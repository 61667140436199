@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html {
    background-color: #FCFCFC;
    height: 100%;
    width: 100%;

    font-family: Montserrat, sans-serif;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 583px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}

.titleContainer {
    text-align: center;
    margin-top: 25px;
}

.QuestionsTitleContainer {
    border-bottom: 2px solid black;
    margin-top: 15px;
    padding-bottom: 2px;
    position: relative;
}

.questionContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-top: 15px;
}

.question {
    width: 100%;
    background-color: rgb(247, 247, 247);
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    border-radius: 5px;
    outline: 2px solid black;
}

.questionTitle {
    font-weight: 700;
}

.inputContainer {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-top: 10px;
}

.inputDiv {
    display: flex;
    gap: 5px;
    align-items: center;
}

.resultContainer {
    margin-top: 50px;
}

.resultData {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-top: 2px solid black;
}

.resultData {
    padding-top: 15px;
}

.priceIndicationContainer {
    margin-top: 50px;
}

.priceIndicationTitleContainer {
    border-bottom: 2px solid black;
    margin-top: 15px;
    padding-bottom: 2px;
}

.defaultFormData {
    position: relative;
    margin-top: 20px;
    padding-bottom: 10px;
    height: fit-content;
}

.formDataInput {
    display: flex;
    gap: 10px;
    height: fit-content !important;
}

.formDataInputfields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    height: fit-content;
    min-height: 20px;
}

.formDataInput p {
    width: 175px;
    font-weight: 700;
}

.formDataInput input {
    width: 75%;
}

.formDataInput textarea {
    width: 75%;
    height: 75px;
    resize: vertical;
    padding: 2.5px;
}

.indicationNotice {
    margin-top: 15px;
    font-weight: 600;
}

.indicationContainer {
    margin-top: 10px;
}

.indication {
    font-weight: 600;
}

.exportButton {
    padding: 10px 5px;
    background-color: black;
    font-weight: 700;
    color: white;
    margin-top: 35px;
    border-radius: 8px;
    transition: 0.25s;
}

.exportButton:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.25s;
}

.defaultCheckListDataTitle {
    border-bottom: 2px solid black;
}

.expand {
    height: fit-content !important;
    width: 75% !important;
    margin-left: 15px;
}

input[type="text"],
input[type="datetime-local"],
textarea {
    border-radius: 2.5px;
    border: none;
    outline: 2px solid black;
    padding-left: 2.5px;
    padding-right: 2.5px;
}

.companyInputs {
    margin-top: 10px;
}

@media print {

    html,
    body {
        overflow-x: unset !important;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
        background-color: #000000 !important;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        max-width: 90%;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 50px;
    }

    .formDataInput {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: fit-content !important;
    }

    .formDataInput input,
    textarea {
        width: 100% !important;
    }
}